$(document).ready(function () {

    var packSwiper = new Swiper('.pack-swiper', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        spaceBetween: 30,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        // If we need pagination
        pagination: {
            el: '.pack-pagination',
        },
    });

    var introSwiper = new Swiper('.intro-swiper', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        spaceBetween: 30,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        // If we need pagination
        pagination: {
            el: '.intro-pagination',
        },
    });

    var teamSwiper = new Swiper('.team-swiper', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        spaceBetween: 30,
        slidesPerView: 'auto',

        // Navigation arrows
        navigation: {
            nextEl: '.team-swiper-button-next',
            prevEl: '.team-swiper-button-prev',
        },
    });

    $("a[href*='#']:not([href='#'])").click(function() {
        if (
            location.hostname == this.hostname
            && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")
        ) {
            var anchor = $(this.hash);
            anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
            if ( anchor.length ) {
                $("html, body").animate( { scrollTop: anchor.offset().top }, 450);
            }
        }
    });

    mapboxgl.accessToken = 'pk.eyJ1IjoieXZhMm4iLCJhIjoiY2poN214a2V2MGQ5dzMycXkzd2w1dm1iMSJ9.O_33A4MTTXyEj1RTv_IeFQ';
    var map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v10',
        zoom:13,
        center: [5.65000,49.60100]
    });

    map.on("load", function () {
        /* Image: An image is loaded and added to the map. */
        map.loadImage("https://i.imgur.com/MK4NUzI.png", function(error, image) {
            if (error) throw error;
            map.addImage("custom-marker", image);
            /* Style layer: A style layer ties together the source and image and specifies how they are displayed on the map. */
            map.addLayer({
                id: "markers",
                type: "symbol",
                /* Source: A data source specifies the geographic coordinate where the image marker gets placed. */
                source: {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features:[{"type":"Feature","geometry":{"type":"Point","coordinates":["5.650","49.601"]}}]}
                },
                layout: {
                    "icon-image": "custom-marker",
                }
            });
        });
    });


});
